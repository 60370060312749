/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MaterialInput } from '@findep/mf-landings-core';
import { CardElevation, TitleIcon, ButtonDegrade } from '@findep/mf-landings-core'
import { Grid, Container, Select, FormControl, InputLabel } from "@material-ui/core"
// import phoneValidate from '../../../helpers/phoneValidate'

//ICONS MATERIAL UI
import PersonIcon from '@material-ui/icons/Person';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { CataloguesService } from '../../../services/LandingV4/catalogues';
// import replaceAcents from '../../../helpers/replaceAcents';
// import { Referencia } from '../../../services/LandingV4';
// import { navigate } from '../../../helpers/queryNavigate';

const cssSelect = css` 
  width:100%;
 `
const root = css`
  flex-grow: 1;
`
const buttonCss = css`
    display: flex;
    justify-content: flex-center;    
`

const catalogues = new CataloguesService();

class ReferenciaCard extends Component {
  constructor(props) {
    super(props)
    this.state={
      _errors: {
        names: {
            error: false,
            errorType: 'Campo obligatorio',
        },
        lastName: {
            error: false,
            errorType: 'Campo obligatorio',
        },
        mothersName: {
            error: false,
            errorType: '',
        },
        razon_social: {
            error: false,
            errorType: ''
        },
        phone: {
            error: false,
            errorType: 'Se requiere un numero de 10 dígitos',
        }
    }
    }
  }
  //   this.state = {
  //     flujo: !((typeof window != 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window != 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
  //     data: { names: '', lastName: '', mothersName: '', phone: '', razon_social: '', relacion: '' },
  //     unitError: { names: false, lastName: false, mothersName: false, phone: false },
  //     relaciones: [],
  //     btnSubmit: false,
  //     _errors: {
  //       names: {
  //         error: false,
  //         errorType: 'Campo obligatorio',
  //       },
  //       lastName: {
  //         error: false,
  //         errorType: 'Campo obligatorio',
  //       },
  //       mothersName: {
  //         error: false,
  //         errorType: '',
  //       },
  //       razon_social: {
  //         error: false,
  //         errorType: ''
  //       },
  //       phone: {
  //         error: false,
  //         errorType: 'Se requiere un numero de 10 dígitos',
  //       }
  //     }

  //   }
  //   // console.log(this.props)
  //   this.handleChange = this.handleChange.bind(this)
  // }

  // handleChange(prop, data) {
  //   const state = {
  //     data: { ...this.state.data, [prop]: data.value }
  //   }

  //   this._handleError(prop, {}, false)
  //   const tmp = Object.assign({}, this.state, state)
  //   this.setState(tmp, () => { })    
  //   // console.log("estoy en handleChange: - " + prop+" - "+ this.state.nombre); 
  // }

  // componentDidMount() {    
  //   console.log(this.props);
  // }

  //   console.log(this.props.referencias.data);

    // if(this.props.pagePath === '/pyme/referencias-personales') {      
    //   const catData = await catalogues.getReferencesPersonal(this.state.flujo.creditApplicationId);        
    //   console.log(catData.data);   
    //   const { referenceTypes } = catData.data;  
    //   this.setState({
    //     relaciones: referenceTypes        
    //   })       
      
    // } else {
    //   const catData = await catalogues.getReferencesCommercial(this.state.flujo.creditApplicationId);
    //   const { referenceTypes } = catData.data;  
    //   this.setState({
    //     relaciones: referenceTypes
    //   });          
    // }
  // }

  // componentDidUpdate(prevProp, prevState) {
  //   if (prevState.data !== this.state.data) {
  //     let dataArr = []
  //     for (var item in this.state.data) {
  //       dataArr = [...dataArr, this.state.data[item]]
  //     }
  //     if (!(dataArr.includes(''))) {        
  //       this.props.onError(false)
  //     } else {
  //       this.props.onError(true)
  //     }
  //   }

  //   const {
  //     names, 
  //     lastName, 
  //     mothersName, 
  //     phone, 
  //     razon_social, 
  //     relacion
  //   } = this.state.data;

  //   if(this.props.pagePath === '/pyme/referencias-personales') {
  //     if(
  //       names != '' && 
  //       lastName != '' && 
  //       mothersName != '' && 
  //       phone != '' &&           
  //       relacion != ''
  //     ) {
  //       if(!prevState.btnSubmit) {
  //           this.setState({ btnSubmit: true })
  //         }
  //       } else if (prevState.btnSubmit) {
  //         this.setState({ btnSubmit: false })
  //       }
  //   } else {
  //     if(
  //       names != '' && 
  //       lastName != '' && 
  //       mothersName != '' && 
  //       phone != '' && 
  //       razon_social != '' &&  
  //       relacion != ''
  //     ) {
  //       if(!prevState.btnSubmit) {
  //           this.setState({ btnSubmit: true })
  //         }
  //       } else if (prevState.btnSubmit) {
  //         this.setState({ btnSubmit: false })
  //       }
  //   }        
  // }
  

  // renderRelacion() {    
  //   const relacion = this.state.relaciones;
    
  //   return (relacion.length > 0) ? (
  //     relacion.map(row => (
  //       <MenuItem key={`${row.id}`} value={row.id}>{row.displayName.toUpperCase()}</MenuItem>

  //     ))
  //   ) : (
  //       <MenuItem value="">Selecciona una opción...</MenuItem>
  //   )
  // }

  // async verificaTelefono() {
  //   const ressponse = await phoneValidate(this.state.flujo.creditApplicationId, this.state.data.phone)
  //   if (ressponse) {
  //     this.setState({ srv_tel: false })
  //   } else {
  //     const _errors = JSON.parse(JSON.stringify(this.state._errors))
  //     _errors.phone = {
  //       error: true,
  //       errorType: 'No fue posible validar tu telefono intente de nuevo'
  //     }
  //     this.setState({ srv_tel: true, _errors })
  //   }
  // }

  // _handleError(prop, data, bool = true) {
  //   let { _errors } = this.state
  //   _errors[prop] = {
  //     ..._errors[prop],
  //     error: bool
  //   }
  //   this.setState({ _errors })
  // }

  // async submitStepTwo() {
  //    this.setState({ btnSubmit: false })
  //    const {
  //     names, 
  //     lastName, 
  //     mothersName, 
  //     phone,       
  //     relacion
  //   } = this.state.data;

  //   // const newValue = component.value.trim().split(/[,\s]+/).join(" ");

  //   let castNames = replaceAcents(names).trim().split(/[,\s]+/).join(" ");
  //   let castApellidoPaterno = replaceAcents(lastName).trim().split(/[,\s]+/).join(" ");
  //   let castApellidoMaterno = replaceAcents(mothersName).trim().split(/[,\s]+/).join(" ");    

  //   const referencia = new Referencia(this.state.flujo.creditApplicationId);
  //   referencia.setNames(castNames);
  //   referencia.setLastName(castApellidoPaterno);
  //   referencia.setMothersName(castApellidoMaterno)
  //   referencia.setMobileNumber(phone);
  //   referencia.setRelacion(relacion);

  //   // Alternate path extrae "/tus-datos" de 
  //   let alternatePath = this.props.pagePath.next.path;
    
  //   const path = (this.props.pagePath === '/pyme/referencias-personales' ? '/references/personal' : '/references/commercial')
  //   await referencia.send(path)
  //   .then((response) => {
  //     sessionStorage.setItem('referencia', JSON.stringify({ phone: phone }))                              
  //     navigate(alternatePath);
  //   })
  //   .catch(error => {
  //     this.setState({ errorService: true, btnSubmit: true })
  //   })
  // }
  
  // setInput = (e, component) => {
  //   const campo = e.target.name    
  //   const newValue = component.value.trim().split(/[,\s]+/).join(" ");
        
  //   const state = {
  //     data: { ...this.state.data, [campo]: component.value }
  //   }

  //   this._handleError(campo, {}, false)
    
  //   const tmp = Object.assign({}, this.state, state)
  //   this.setState(tmp, () => {

  //     if (campo === "phone") {

  //       if (this.state.data.phone.length >= 10) {
  //         this.verificaTelefono()
  //       } else {
  //         this.setState({ srv_tel: true })
  //       }
  //     }
  //   })    
  // }  

  render() {
    return (
      <CardElevation>
        <div css={root}>
          <Container justify="center" >

            <Grid container justify="center">
              <Grid item style={{ textAlign: "center" }}>
                <TitleIcon icon={PersonIcon} title={`${this.props.textTitleCard} ${this.props.id+1}`} textColor />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2} justify="center">
              {this.props.path === "referencias-comerciales" &&
                <Grid item xs={12} md={10}>
                  <MaterialInput
                    id={`referencias-razon-${this.props.id}-input`}
                    type="text"
                    name="razon_social"
                    pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                    accept="A-ZÑÁÉÍÓÚÜ\s.//"
                    label="Razón social"
                    transform={(value) => value.toUpperCase().slice(0, 50)}
                    onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                    required
                    error={this.props._errors.razon_social.error}
                    onError={(error) => this.props._handleError('razon_social', error, this.props.id)}
                    errorMessage={this.props._errors.razon_social.error ? 'El campo es obligatorio' : 'Ingresa el nombre comercial de la referencia'}
                    value={this.props.data.razon_social}
                    helperText="Ingresa el nombre comercial de la referencia"
                    disabled={this.props.activeInput}    
                  />
                </Grid>
              }
              
              <Grid item xs={12} md={10}>
                <MaterialInput
                  id={`referencias-nombres-${this.props.id}-input`}
                  type="text"
                  name="names"
                  pattern="[A-ZÑÁÉÍÓÚÜ]{2,}( [A-ZÑÁÉÍÓÚÜ]{2})?"
                  accept=" A-ZÑÁÉÍÓÚÜ"
                  label="Nombre(s)"
                  transform={(value) => value.toUpperCase().slice(0, 50)}
                  onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                  required
                  error={this.props._errors.names.error}
                  onError={(error) => this.props._handleError('names', error, this.props.id)}
                  errorMessage={this.props._errors.names.errorType}
                  disabled={                     
                    this.props.path === 'referencias-comerciales' 
                      ? (this.props._errors.razon_social.error || this.props.data.razon_social === '')
                      : this.props.activeInput
                    }
                  value={this.props.data.names}
                  helperText="Ingresa tu(s) nombre(s)"
                />
              </Grid>

               <Grid item xs={12} md={10}>
                <MaterialInput
                  id={`referencias-apellido-paterno-${this.props.id}-input`}
                  type="text"
                  name="lastName"
                  pattern="[A-ZÑÁÉÍÓÚÜ]{2,}( [A-ZÑÁÉÍÓÚÜ]{2})?"
                  accept=" A-ZÑÁÉÍÓÚÜ"
                  label="Apellido Paterno"
                  transform={(value) => value.toUpperCase().slice(0, 50)}
                  onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                  required
                  error={this.props._errors.lastName.error}
                  onError={(error) => this.props._handleError('lastName', error, this.props.id)}
                  errorMessage={this.props._errors.lastName.errorType}
                  value={this.props.data.lastName}
                  disabled={this.props._errors.names.error || this.props.data.names === '' }
                  helperText="Ingresa tu apellido paterno"
                />
              </Grid>
             
              <Grid item xs={12} md={10}>
                <MaterialInput
                  id={`referencias-apellido-materno-${this.props.id}-searchbar-input`}
                  type="text"
                  name="mothersName"
                  pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                  accept=" A-ZÑÁÉÍÓÚÜ"
                  label="Apellido Materno"
                  transform={(value) => value.toUpperCase().slice(0, 50)}
                  onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                  error={this.props._errors.mothersName.error}
                  onError={(error) => this.props._handleError('mothersName', error, this.props.id)}
                  disabled={this.props._errors.lastName.error || this.props.data.lastName === '' }
                  errorMessage={this.props._errors.mothersName.errorType}
                  value={this.props.data.mothersName}
                  helperText="Ingresa tu apellido materno"
                />
              </Grid>
               
              <Grid item xs={12} md={10}>
                <FormControl variant="outlined" css={cssSelect}>
                  <InputLabel id="referencia-relacion-select-label">Relación</InputLabel>
                  <Select
                    onChange={(_event, component) => this.props.onChangeSelect('relacion', { value: _event.target.value + '' }, this.props.id)}
                    labelId="referencia-relacion-select-label"
                    id="referencia-relacion-select"
                    value={this.props.data.relacion}
                    disabled={this.props._errors.mothersName.error || this.props.data.lastName === '' || this.props._errors.lastName.error}
                    required={true}
                    label="Relación"
                  >
                    {
                      this.props.renderSelect()
                    }
                  </Select>
                </FormControl>
              </Grid> 

              <Grid item xs={12} md={10}>
                <MaterialInput
                  id={`referencias-telefono-${this.props.id}-input`}
                  type="text"
                  name="phone"
                  pattern="^([2-9])(?!0123456789|1234567890|0987654321|9876543210|\1{9})\d{9}$"
                  accept="0-9"
                  label="Telefono"
                  transform={(value) => value.slice(0, 10)}
                  onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                  required
                  onError={(error) => this.props._handleError('phone', error, this.props.id)}
                  errorMessage={this.props._errors.phone.error ? this.props._errors.phone.errorType : 'Ingresa tu telefono(10 digitos)'}
                  error={this.props._errors.phone.error}
                  disabled={!this.props.data.relacion}
                  value={this.props.data.phone}
                  helperText="Ingresa tu teléfono(10 dígitos)"
                />
              </Grid>              

                { (this.props.id + 1) === this.props.activeButton && 
                <Grid container justify="center">
                  <Grid item>
                    <br />
                    <div css={buttonCss} >
                      <ButtonDegrade
                        id="referencias-continuar-solicitud-button"
                        icon={ArrowForwardIcon}
                        textButton='Continuar con solicitud'
                        onClick={() => this.props.submitStepTwo()}
                        disabled={!this.props.btnSubmit}                        
                      />
                    </div>
                  </Grid>
                </Grid>
              }

            </Grid>
          </Container>
        </div>
      </CardElevation>
    );
  }
}


ReferenciaCard.propTypes = {
  onChange: PropTypes.func,
  

};


export default ReferenciaCard;
